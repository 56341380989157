import React, { useEffect, useState } from 'react'
import Img from "../image.jpeg"
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from '../firebase';
import addNotification from 'react-push-notification';
// import Audio from "../MenuMessage.mp3"

const User = ({ user1, user, selectUser, chat }) => {
    const user2 = user?.uid;
    const [data, setData] = useState('');

    useEffect(() => {
        const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
        let unsub = onSnapshot(doc(db, 'lastMsg', id), doc => {
            setData(doc.data());
            console.log(user1, doc.data().from, doc.data());
            if (user1 != doc.data().from && doc.data().unread) {
                console.log('send notify');
                alert("Nowa wiadomość od " + doc.data().fromName);
                //messageSound();
                notification(doc.data().fromName, doc.data().text);
            }
        });
        return () => unsub();
    }, []);

    const messageSound = () => {
        let audio = new Audio("../MenuMessage.mp3");
        console.log(audio);
        audio.play();
    }

    const notification = (userName, userText) => {
        addNotification({
            title: 'Nowa wiadomość od ' + userName,
            message: userText,
            theme: 'darkblue',
            native: true // when using native, your OS will handle theming.
        });
    }



    return (
        <>
            <div className={`user_wrapper ${chat.name === user.name && "selected_user"}`} onClick={() => selectUser(user)}>
                <div className="user_info">
                    <div className="user_detail">
                        <img src={user.avatar || Img} alt="avatar" className="avatar" />
                        <h4>{user.name}</h4>
                        {data?.from !== user1 && data?.unread && (<small className='unread'>New</small>)}
                    </div>
                    <div className={`user_status ${user.isOnline ? "online" : "offline"}`}></div>
                </div>
                {data && (
                    <p className='truncate'>
                        <strong>{data.from === user1 ? 'Ja:' : null}</strong>
                        {data.text}
                    </p>
                )}
            </div>
            <div onClick={() => selectUser(user)} className={`sm_container ${chat.name === user.name && "selected_user"}`}>
                <img src={user.avatar || Img} alt="avatar" className="avatar sm_screen" />
                {data?.from !== user1 && data?.unread && (<small className='unread unread_sm'>New</small>)}
                <div className={`user_status US_sm ${user.isOnline ? "online" : "offline"}`} ></div>
            </div>
        </>
    );
}

export default User
