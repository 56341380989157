import React, { useRef, useEffect } from 'react'
import Moment from 'react-moment'
import Attachment from './svg/Attachment'

const Message = ({ msg, user1 }) => {
    const scrollRef = useRef();
    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [msg]);
    
    return (
        <div className={`message_wrapper ${msg.from === user1 ? "own" : ""}`} ref={scrollRef}>
            <p className={msg.from === user1 ? "me" : "friend"}>
                {msg.media && ['jpg','jpeg','png','gif'].includes(msg.media.split(/[#?]/)[0].split('.').pop().trim()) ? <img src={msg.media} alt={msg.text} /> : null}
                {msg.media && !['jpg','jpeg','png','gif'].includes(msg.media.split(/[#?]/)[0].split('.').pop().trim()) ? <a href={msg.media} target="_blank" download><Attachment /> Pobierz plik</a> : null}
                <br />{msg.text}
                <br />
                <small>
                    <Moment fromNow>{msg.createdAt.toDate()}</Moment>
                </small>
            </p>
        </div>
    );
};

export default Message
