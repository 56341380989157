import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { updateDoc, doc} from 'firebase/firestore';
import { useNavigate  } from 'react-router-dom';

const Login = () => {

    const loginPasswordUrl = window.location.href;
    const loginPassword = new URL(loginPasswordUrl);
    const userNameUrl = loginPassword.searchParams.get("name");
    var loginUrl = loginPassword.searchParams.get("email");
    var passwordUrl = loginPassword.searchParams.get("password");
    
    if (localStorage.getItem('email')) {
        loginUrl = localStorage.getItem('email');
    }
    if (localStorage.getItem('password')) {
        passwordUrl = localStorage.getItem('password');
    }

    if (loginUrl && passwordUrl) {
        if (localStorage.getItem('email')) {
            localStorage.removeItem("email");
            console.log('remove',  localStorage.removeItem("email"));
        }
        if (localStorage.getItem('password')) {
            localStorage.removeItem("password");
            console.log('remove',  localStorage.removeItem("password"));
        }
        instaLogin();
    }

    const [data, setData] = useState({
        email: loginUrl,
        password: passwordUrl,
        error: '',
        loading: false
    });

    const history = useNavigate();

    const { email, password, error, loading } = data;

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setData({ ...data, error: null, loading: true });
        if (!email || !password) {
            setData({ ...data, error: 'All fields are required' });
        }
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            await updateDoc(doc(db, 'users', result.user.uid), {
                isOnline: true
            });
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
            setData({ 
                email: '', 
                password: '', 
                error: null, 
                loading: false 
            });
            history("/");
        } catch (err) {
            setData({ ...data, error: err.message, loading: false });
        }
    };

    async function instaLogin() {
        try {
            const result = await signInWithEmailAndPassword(auth, loginUrl, passwordUrl);
            console.log(result);
            await updateDoc(doc(db, 'users', result.user.uid), {
                isOnline: true
            });
            localStorage.setItem('email', loginUrl);
            localStorage.setItem('password', passwordUrl);
            history("/");
        } catch (err) {
            alert('Brak użytkownika, skontaktuj się z administracją aplikacji');
            window.location.replace("https://agencjamedialna.pro/kontakt.html");
        }
    }

    return (
        <section>
            <h3>ADChat</h3>
            <h3>Witaj {userNameUrl}!</h3>
            <h5>Naciśnij przycisk Start Chat aby dołączyć do rozmowy</h5>
            <form className="form" onSubmit={handleSubmit}>
                <div className="input_container">
                    <input type={loginUrl ? 'hidden' : 'text'} name="email" value={email} onChange={handleChange} placeholder='Wprowadź adres e-mail' />
                </div>
                <div className="input_container">
                    <input type={passwordUrl ? 'hidden' : 'password'} name="password" value={password} onChange={handleChange} placeholder='Wprowadź hasło' />
                </div>
                {error ? <p className="error">{error}</p> : null}
                <div className="btn_container">
                    <button className="btn" disabled={loading}>{loading ? 'Loading chat...' : 'Login'}</button>
                </div>
            </form>
            <small>Version: 0.0.4</small>
        </section>
    )
}

export default Login
